import React, { useState, useEffect, useRef } from 'react';
import './Stats.css';
import Chart from 'chart.js/auto';
import { Helmet } from 'react-helmet';

function Stats() {
    const [stats, setStats] = useState({
        uniqueMobileDevicesToday: null,
        uniqueOtherDevicesToday: null,
        totalDevicesToday: null,
        uniqueMobileDevicesThisWeek: null,
        uniqueOtherDevicesThisWeek: null,
        totalDevicesThisWeek: null,
        uniqueMobileDevicesThisMonth: null,
        uniqueOtherDevicesThisMonth: null,
        totalDevicesThisMonth: null,
        totalChaptersReadToday: null,
        totalChaptersReadThisWeek: null,
        totalChaptersReadThisMonth: null,
    });

    // Nouvel état pour les données du graphique
    const [chartData, setChartData] = useState(null);
    const [chartDataScansRead, setChartDataScansRead] = useState(null);
    const [chartDataVisitByHourLasr24Hours, setChartDataVisitByHourLasr24Hours] = useState(null);
    const [chartDataVisitByHourLasr24HoursDistinct, setChartDataVisitByHourLasr24HoursDistinct] = useState(null);

    const [topScans, setTopScans] = useState([]);
    const [topScansToday, setTopScansToday] = useState([]);
    const [connectionsPerUser, setConnectionsPerUser] = useState({});
    const [deadMangas, setDeadMangas] = useState([]);

    const baseURL = 'https://kanjan.fr/Scans/';

    // Référence au canvas du graphique
    const chartRef = useRef(null);
    const chartRef2 = useRef(null);
    const chartRef3 = useRef(null);
    const chartRef4 = useRef(null);


    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await fetch('https://kanjan.fr/Scans/stats.php?action=connectionsPerUserToday');
                const data = await response.json();
                setConnectionsPerUser(data);
            } catch (error) {
                console.error('Error fetching connections per user today:', error);
            }
            try {
                const response = await fetch(`${baseURL}/stats.php?action=visitsByHourLast24Hours`);
                const data = await response.json();
                setChartDataVisitByHourLasr24Hours(data);
            } catch (error) {
                console.error('Error fetching visits per hour:', error);
            }
            try {
                const response = await fetch(`${baseURL}/stats.php?action=visitsByHourLast24HoursDistinct`);
                const data = await response.json();
                setChartDataVisitByHourLasr24HoursDistinct(data);
            } catch (error) {
                console.error('Error fetching visits per hour:', error);
            }
            try {
                const response = await fetch(`${baseURL}/stats.php?action=chartData`);
                const data = await response.json();
                setChartData(data);
            } catch (error) {
                console.error('Error fetching chart data:', error);
            }
            try {
                const response = await fetch(`${baseURL}/stats.php?action=chartDataScansRead`);
                const data = await response.json();
                setChartDataScansRead(data);
            } catch (error) {
                console.error('Error fetching chart data:', error);
            }
            try {
                const responseTopScans = await fetch(`${baseURL}/stats.php?action=topScansRead`);
                const dataTopScans = await responseTopScans.json();
                setTopScans(dataTopScans);
            } catch (error) {
                console.error('Error fetching top scans:', error);
            }
            try {
                const responseTopScansToday = await fetch(`${baseURL}/stats.php?action=topScansReadToday`);
                const dataTopScansToday = await responseTopScansToday.json();
                setTopScansToday(dataTopScansToday);
            } catch (error) {
                console.error('Error fetching top scans today:', error);
            }
            try {
                const responseDeadMangas = await fetch(`${baseURL}/stats.php?action=deadMangas`);
                const dataDeadMangas = await responseDeadMangas.json();
                setDeadMangas(dataDeadMangas);
            } catch (error) {
                console.error('Error fetching dead mangas:', error);
            }
            try {
                const responseChaptersToday = await fetch(`${baseURL}/stats.php?action=totalChaptersReadToday`);
                const dataChaptersToday = await responseChaptersToday.json();
                setStats(prevStats => ({ ...prevStats, totalChaptersReadToday: dataChaptersToday.total_chapters }));

                const responseChaptersThisWeek = await fetch(`${baseURL}/stats.php?action=totalChaptersReadThisWeek`);
                const dataChaptersThisWeek = await responseChaptersThisWeek.json();
                setStats(prevStats => ({ ...prevStats, totalChaptersReadThisWeek: dataChaptersThisWeek.total_chapters }));

                const responseChaptersThisMonth = await fetch(`${baseURL}/stats.php?action=totalChaptersReadThisMonth`);
                const dataChaptersThisMonth = await responseChaptersThisMonth.json();
                setStats(prevStats => ({ ...prevStats, totalChaptersReadThisMonth: dataChaptersThisMonth.total_chapters }));

                const responseToday = await fetch(`${baseURL}/stats.php?action=uniqueMobileDevicesToday`);
                const dataToday = await responseToday.json();
                setStats(prevStats => ({ ...prevStats, uniqueMobileDevicesToday: dataToday.unique_mobile_devices }));

                const responseOtherToday = await fetch(`${baseURL}/stats.php?action=uniqueOtherDevicesToday`);
                const dataOtherToday = await responseOtherToday.json();
                setStats(prevStats => ({ ...prevStats, uniqueOtherDevicesToday: dataOtherToday.unique_other_devices }));

                const responseTotalToday = await fetch(`${baseURL}/stats.php?action=totalDevicesToday`);
                const dataTotalToday = await responseTotalToday.json();
                setStats(prevStats => ({ ...prevStats, totalDevicesToday: dataTotalToday.total_devices }));

                const responseMobileThisWeek = await fetch(`${baseURL}/stats.php?action=uniqueMobileDevicesThisWeek`);
                const dataMobileThisWeek = await responseMobileThisWeek.json();
                setStats(prevStats => ({ ...prevStats, uniqueMobileDevicesThisWeek: dataMobileThisWeek.unique_mobile_devices }));

                const responseOtherThisWeek = await fetch(`${baseURL}/stats.php?action=uniqueOtherDevicesThisWeek`);
                const dataOtherThisWeek = await responseOtherThisWeek.json();
                setStats(prevStats => ({ ...prevStats, uniqueOtherDevicesThisWeek: dataOtherThisWeek.unique_other_devices }));

                const responseTotalThisWeek = await fetch(`${baseURL}/stats.php?action=totalDevicesThisWeek`);
                const dataTotalThisWeek = await responseTotalThisWeek.json();
                setStats(prevStats => ({ ...prevStats, totalDevicesThisWeek: dataTotalThisWeek.total_devices }));

                const responseMobileThisMonth = await fetch(`${baseURL}/stats.php?action=uniqueMobileDevicesThisMonth`);
                const dataMobileThisMonth = await responseMobileThisMonth.json();
                setStats(prevStats => ({ ...prevStats, uniqueMobileDevicesThisMonth: dataMobileThisMonth.unique_mobile_devices }));

                const responseOtherThisMonth = await fetch(`${baseURL}/stats.php?action=uniqueOtherDevicesThisMonth`);
                const dataOtherThisMonth = await responseOtherThisMonth.json();
                setStats(prevStats => ({ ...prevStats, uniqueOtherDevicesThisMonth: dataOtherThisMonth.unique_other_devices }));

                const responseTotalThisMonth = await fetch(`${baseURL}/stats.php?action=totalDevicesThisMonth`);
                const dataTotalThisMonth = await responseTotalThisMonth.json();
                setStats(prevStats => ({ ...prevStats, totalDevicesThisMonth: dataTotalThisMonth.total_devices }));


            } catch (error) {
                console.error('Error fetching stats:', error);
            }
        };
        fetchStats();
    }, []);

    useEffect(() => {
        // Créer ou mettre à jour le graphique pour chartDataVisitByHourLasr24Hours
        if (chartDataVisitByHourLasr24Hours) {
            if (chartRef3.current) {
                // Si un graphique existe déjà, le détruire
                chartRef3.current.destroy();
            }

            // Transformer les données de l'API en tableaux de labels et de données
            const labels = Object.keys(chartDataVisitByHourLasr24Hours).map(timestamp => {
                // Convertir le timestamp en objet Date
                const date = new Date(timestamp);
                // Renvoyer la date sous forme de chaîne de caractères lisible
                return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            });
            const data = Object.values(chartDataVisitByHourLasr24Hours).reverse(); // Inverser les données

            // Inverser également les labels
            const reversedLabels = labels.slice().reverse();
        }
    }, [chartDataVisitByHourLasr24Hours]);

    useEffect(() => {
        // Créer ou mettre à jour le graphique pour chartDataVisitByHourLasr24Hours
        if (chartDataVisitByHourLasr24HoursDistinct) {
            if (chartRef4.current) {
                // Si un graphique existe déjà, le détruire
                chartRef4.current.destroy();
            }

            // Transformer les données de l'API en tableaux de labels et de données
            const labels = Object.keys(chartDataVisitByHourLasr24HoursDistinct).map(timestamp => {
                // Convertir le timestamp en objet Date
                const date = new Date(timestamp);
                // Renvoyer la date sous forme de chaîne de caractères lisible
                return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            });
            const data = Object.values(chartDataVisitByHourLasr24HoursDistinct).reverse(); // Inverser les données

            // Inverser également les labels
            const reversedLabels = labels.slice().reverse();

            // Créer le nouveau graphique
            const ctx = document.getElementById('myChart4').getContext('2d');
            chartRef4.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: reversedLabels,
                    datasets: [
                        {
                            label: 'Visites uniques par heure (dernières 24 heures)',
                            data: data,
                            fill: false,
                            borderColor: 'rgb(255, 205, 86)',
                            tension: 0.1
                        }
                    ]
                }
            });
        }
    }, [chartDataVisitByHourLasr24HoursDistinct]);

    useEffect(() => {
        // Créer ou mettre à jour le graphique
        if (chartData) {
            if (chartRef2.current) {
                // Si un graphique existe déjà, le détruire
                chartRef2.current.destroy();
            }

            // Créer le nouveau graphique
            const ctx = document.getElementById('myChart').getContext('2d');
            chartRef2.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: chartData.labels.map(label => label.split('-').slice(1).join('-')), // Formater les étiquettes pour n'afficher que le mois et le jour
                    datasets: [
                        {
                            label: 'Nombre de connexions',
                            data: chartData.data,
                            fill: false,
                            borderColor: 'rgb(75, 192, 192)',
                            tension: 0.1
                        }
                    ]
                }
            });
        }
    }, [chartData]);

    useEffect(() => {
        // Créer ou mettre à jour le graphique pour chartDataScansRead
        if (chartDataScansRead) {
            if (chartRef.current) {
                // Si un graphique existe déjà, le détruire
                chartRef.current.destroy();
            }

            // Créer le nouveau graphique
            const ctx = document.getElementById('myChart2').getContext('2d');
            chartRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: chartDataScansRead.labels.map(label => label.split('-').slice(1).join('-')), // Formater les étiquettes pour n'afficher que le mois et le jour
                    datasets: [
                        {
                            label: 'Nombre de lectures de scans',
                            data: chartDataScansRead.data,
                            fill: false,
                            borderColor: 'rgb(255, 99, 132)',
                            tension: 0.1
                        }
                    ]
                }
            });
        }
    }, [chartDataScansRead]);

    return (
        <div>
	    <Helmet>
                <title>Kanjan</title>
                <meta name="description" content="Lecture GRATUITE - Scans et Webtoons en VF - Vos œuvres préférées sur Kanjan" />
            </Helmet>
            <h1>Tableau de bord</h1>
            <div className="stats-wrapper">
                <div className="stats-left">
                    <div className="stats-container">
                        <h3 className="stats-heading">Appareils connectés</h3>
                        <table className="stats-table">
                            <thead>
                            <tr>
                                <th>Période</th>
                                <th>Mobiles</th>
                                <th>Autres</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>aujourd'hui</td>
                                <td>{stats.uniqueMobileDevicesToday}</td>
                                <td>{stats.uniqueOtherDevicesToday}</td>
                                <td>{stats.totalDevicesToday}</td>
                            </tr>
                            <tr>
                                <td>cette semaine</td>
                                <td>{stats.uniqueMobileDevicesThisWeek}</td>
                                <td>{stats.uniqueOtherDevicesThisWeek}</td>
                                <td>{stats.totalDevicesThisWeek}</td>
                            </tr>
                            <tr>
                                <td>ce mois-ci</td>
                                <td>{stats.uniqueMobileDevicesThisMonth}</td>
                                <td>{stats.uniqueOtherDevicesThisMonth}</td>
                                <td>{stats.totalDevicesThisMonth}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="stats-container">
                        <h3 className="stats-heading">Chapitres lus</h3>
                        <table className="stats-table">
                            <thead>
                            <tr>
                                <th>Période</th>
                                <th>Chapitres lus</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>aujourd'hui</td>
                                <td>{stats.totalChaptersReadToday}</td>
                            </tr>
                            <tr>
                                <td>cette semaine</td>
                                <td>{stats.totalChaptersReadThisWeek}</td>
                            </tr>
                            <tr>
                                <td>ce mois-ci</td>
                                <td>{stats.totalChaptersReadThisMonth}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="stats-container">
                        <h3 className="stats-heading">Nombre de connexions par personne aujourd'hui ( &lt; 30 )</h3>
                        <table className="stats-table">
                            <thead>
                            <tr>
                                <th>Nombre de connexions</th>
                                <th>Nombre de personnes</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.entries(connectionsPerUser).map(([connections, count]) => (
                                <tr key={connections}>
                                    <td>{connections}</td>
                                    <td>{count}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="chart-container">
                        <h3 className="stats-heading">Évolution des connexions des 30 derniers jours</h3>
                        <canvas id="myChart"></canvas>
                    </div>
                    <div className="chart-container">
                        <h3 className="stats-heading">Évolution des lectures des 30 derniers jours</h3>
                        <canvas id="myChart2"></canvas>
                    </div>
                </div>
                <div className="stats-right">
                    <div className="stats-container">
                        <h3 className="stats-heading">Nombre de scans morts : {deadMangas.length}</h3>
                        <table className="stats-table">
                            <thead>
                            <tr>
                                <th>Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            {deadMangas.map((manga, index) => (
                                <tr key={index}>
                                    <td>{manga.manga_name}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="chart-container">
                        <h3 className="stats-heading">Visites par heure (dernières 24 heures)</h3>
                        <canvas id="myChart4"></canvas>
                    </div>
                    <div className="stats-container">
                        <h3 className="stats-heading">Top 10 des scans les plus lus aujourd'hui</h3>
                        <table className="stats-table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Scan Title</th>
                                <th>Total Reads</th>
                            </tr>
                            </thead>
                            <tbody>
                            {topScansToday.map((scan, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{scan.scan_title}</td>
                                    <td>{scan.total_reads}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="stats-container">
                        <h3 className="stats-heading">Top 20 des scans les plus lus</h3>
                        <table className="stats-table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Scan ID</th>
                                <th>Nombre de lectures</th>
                            </tr>
                            </thead>
                            <tbody>
                            {topScans.map((scan, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{scan.scan_title}</td>
                                    <td>{scan.total_reads}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Stats;
